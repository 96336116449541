import { graphql, PageProps } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useState } from 'react'
import { Button, Collapse, Container, Modal, ModalDialogProps } from 'react-bootstrap'
import { Gallery, Image } from '../components/Gallery'
import { Heading } from '../components/Heading'
import { Layout } from '../components/Layout'
import { Section } from '../components/Section'
import { Spinner } from '../components/Spinner'
import SEO from '../components/seo'
import { CollectionQuery, Edges, Must } from '../types'

const getPhoto = (photos: Edges<CollectionQuery, 'photos'>, id: string) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  photos.find(({ node }) => node.id === id)!.node.childImageSharp.fluid

export default function Template({ data }: PageProps<Must<CollectionQuery>>) {
  const [image, setImage] = useState('')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [open, setOpen] = useState(false)

  // re-enable when mobile navigates to new page
  // const handleClose = (event: React.MouseEvent) => {
  //   if (!event.target || event.target === event.currentTarget) {
  //     setShow(false)
  //   }
  // }

  const handleClose = () => {
    setShow(false)
    if (goBack) {
      // the picture was open with a click on the gallery so use `back`
      window.history.back()
    } else {
      // the picture was opened by visiting it's url directly so replace state
      window.history.replaceState(
        '',
        document.title,
        window.location.pathname + window.location.search
      )
    }
  }

  const handleShow = (id: string) => {
    setLoading(true)
    setImage(id)
    setShow(true)
  }

  useEffect(() => {
    const handler = () =>
      window.location.hash ? handleShow(window.location.hash.substring(1)) : setShow(false)

    handler()

    window.addEventListener('popstate', handler)
    return () => window.removeEventListener('popstate', handler)
  }, [])

  const Dialog = ({ className, ...props }: ModalDialogProps) => {
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    return <div className={`${className || ''} lightbox`} onClick={handleClose} {...props} />
  }

  const { markdownRemark, photos } = data
  const { frontmatter, html } = markdownRemark

  const photo = image && getPhoto(photos.edges, image)

  return (
    <Layout className="bg-dark text-white">
      <SEO title={frontmatter.title} />
      <article>
        <Section as={Container} className="page lead text-center ">
          <Heading center>{frontmatter.title}</Heading>
          <div className="text-center page lead" dangerouslySetInnerHTML={{ __html: html }} />

          {frontmatter.credits && (
            <>
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="credits-collapse-text"
                aria-expanded={open}
                variant="link"
              >
                VIEW CREDITS
              </Button>
              <Collapse in={open}>
                <div>
                  <h2 className="text-uppercase mt-4">Credits</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.credits.replace(/\n/g, '<br/>'),
                    }}
                  />
                </div>
              </Collapse>
            </>
          )}
        </Section>
        <Container fluid>
          <Gallery className="gallery small mb-5">
            {photos.edges.map(({ node }) => {
              const img = node.childImageSharp.fluid
              const isWide = img.aspectRatio > 1
              return (
                <Image
                  span={isWide ? 2 : 1}
                  key={node.id}
                  className={`gallery-image ${isWide ? 'gallery-image-wide' : ''}`}
                >
                  <a href={`#${node.id}`} onClick={() => setGoBack(true)}>
                    <figure>
                      <Img fluid={img} />
                      <figcaption>View Image</figcaption>
                    </figure>
                  </a>
                </Image>
              )
            })}
          </Gallery>
        </Container>
      </article>
      <Modal show={show} onHide={handleClose} dialogAs={Dialog}>
        {photo && (
          <img
            src={photo.src}
            srcSet={photo.srcSet}
            style={{ backgroundImage: `url(${photo.base64})` }}
            onLoad={() => setLoading(false)}
            alt=""
          />
        )}
        {loading && <Spinner centered />}
      </Modal>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Collection($path: String!, $dir: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        credits
      }
    }
    photos: allFile(
      filter: {
        absolutePath: { regex: $dir }
        children: { elemMatch: { internal: { type: { eq: "ImageSharp" } } } }
      }
      sort: { order: ASC, fields: absolutePath }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
