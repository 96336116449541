import { debounce } from 'lodash-es'
import { ReactNodeArray } from 'prop-types'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

const reOrderChildren = (columns: number, children: ReactNode) => {
  const items = [] as ReactNodeArray
  const itemsMoved = [] as ReactElement[]
  let row = 0

  const rowAdd = (span: number) => {
    row += span
    if (row === columns) {
      row = 0
    }
  }

  const reorder = (element: ReactNode) => {
    if (!React.isValidElement(element)) return

    for (let i = 0; i < itemsMoved.length; ) {
      if (row + itemsMoved[i].props.span <= columns) {
        rowAdd(itemsMoved[i].props.span)
        items.push(itemsMoved.splice(i, 1))
      } else {
        i += 1
      }
    }

    if (row + element.props.span <= columns) {
      rowAdd(element.props.span)
      items.push(element)
    } else {
      itemsMoved.push(element)
    }
  }

  // TODO: if first iteration can't produce full bottom row then remove bottom row items, add to end of itemsMoved and re-run reorder on them

  React.Children.forEach(children, reorder)
  itemsMoved.splice(0, itemsMoved.length).forEach(reorder)
  items.push(...itemsMoved)
  // items.splice(0, items.length).forEach(reorder)
  return items
}

// sorts sizes in reverse order
const sortSizes = (sizes: { [size: number]: number }) =>
  Object.entries(sizes)
    .map(items => items.map(Number))
    .sort((a, b) => b[0] - a[0])

export const GalleryContext = React.createContext(1)

interface GalleryProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNodeArray
  span?: number
  sizes?: {
    [size: number]: number
  }
}

const defaultSizes = {
  0: 2,
  576: 2,
  768: 4,
  992: 6,
  1200: 6,
}

export const Gallery = ({ children, sizes: s = defaultSizes, style, ...props }: GalleryProps) => {
  const [items, setItems] = useState(children)
  const [width, setWidth] = useState(0)
  const [columns, setColumns] = useState(1)
  // const [sizes, setSizes] = useState<number[][]>()
  const sizes = sortSizes(s)

  // sort sizes
  // useEffect(() => setSizes(sortSizes(s)), [s])

  // set width and register resize event
  useEffect(() => {
    const updateWidth = debounce(() => setWidth(window.innerWidth), 50)
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  // set columns for current viewport
  useEffect(() => {
    setColumns(sizes.find(([size]) => size <= width)?.pop() || 1)
  }, [sizes, width])

  // reorder children to fit in current viewport
  useEffect(() => {
    if (columns > 1) {
      setItems(reOrderChildren(columns, children))
    } else {
      setItems(children)
    }
  }, [children, columns])

  return (
    <GalleryContext.Provider value={100 / columns}>
      <div {...props} style={{ ...style, display: 'flex', flexWrap: 'wrap' }}>
        {items}
      </div>
    </GalleryContext.Provider>
  )
}
