import React from 'react'
import s from './Spinner.module.scss'

interface SpinnerProps {
  centered?: boolean
}

export const Spinner = ({ centered }: SpinnerProps) => (
  <div className={`${s.loader} ${centered ? s.centered : ''}`}>Loading...</div>
)
