import React, { useContext } from 'react'
import { GalleryContext } from './Gallery'

interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
  span?: number
}

export const Image = ({ span = 1, style, children, ...props }: ImageProps) => {
  const x = useContext(GalleryContext) * span
  const width = x < 100 ? x : 100
  return (
    <div
      {...props}
      style={{
        ...style,
        maxWidth: `${width}%`,
        flex: `0 0 ${width}%`,
      }}
    >
      {children}
    </div>
  )
}
